import useAuth from "@/components/auth/useAuth"
import { TextField } from "@/components/util/form/fields"
import Submit from "@/components/util/form/Submit"
import { Grid, Typography } from "@material-ui/core"
import { Field, Form, Formik } from "formik"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"

const ForgotSchema = Yup.object().shape({
  email: Yup.string().email().required("Required")
})

const initialValues = {
  email: ""
}

export default function ForgotForm() {
  const [sent, setSent] = useState(false)
  const [passwordAlreadySent, setPasswordAlreadySent] = useState(false)
  const { forgot } = useAuth()
  const { t } = useTranslation()

  if (sent) {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography>{t("Password Reset email sent.")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>{t("Please check your email.")}</Typography>
        </Grid>
      </Grid>
    )
  }
  if (passwordAlreadySent) {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography color="error">
            {t("Password reset email already sent.")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            {t("Please check your spam or junk mail folder.")}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ForgotSchema}
      onSubmit={({ email }, { setStatus, setSubmitting }) => {
        forgot(email)
          .then(response => {
            if (response?.data?.message === "email already sent") {
              setPasswordAlreadySent(true)
            } else {
              setSent(true)
            }
          })
          .catch(error => {
            if (error.response && error.response.status === 422) {
              setStatus(error.response.data.errors)
              setSubmitting(false)
            }

            return Promise.reject(error)
          })
      }}
    >
      <Form>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h4">Visual Alpha</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>{t("Reset Password")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Field
              name="email"
              type="email"
              autoComplete="username"
              label={t("Email")}
              component={TextField}
              margin="dense"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Submit label={t("Send")} fullWidth />
          </Grid>
        </Grid>
      </Form>
    </Formik>
  )
}
