import LanguageChoice from "@/components/app/LanguageChoice"
import NotificationLink from "@/components/app/NotificationLink"
import ModuleSection from "@/components/auth/ModuleSection"
import useAuth from "@/components/auth/useAuth"
import logoutRedirect from "@/helpers/logout"
import {
  Divider,
  List,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem
} from "@material-ui/core"
import AccountBalanceIcon from "@material-ui/icons/AccountBalance"
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet"
import AccountTreeIcon from "@material-ui/icons/AccountTree"
import BookIcon from "@material-ui/icons/Book"
import BookmarksIcon from "@material-ui/icons/Bookmarks"
import BusinessIcon from "@material-ui/icons/Business"
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter"
import CardTravelIcon from "@material-ui/icons/CardTravel"
import EqualizerIcon from "@material-ui/icons/Equalizer"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import ExtensionIcon from "@material-ui/icons/Extension"
import GroupIcon from "@material-ui/icons/Group"
import LanguageIcon from "@material-ui/icons/Language"
import LocalATMIcon from "@material-ui/icons/LocalAtm"
import PieChartIcon from "@material-ui/icons/PieChart"
import SettingsIcon from "@material-ui/icons/Settings"
import ShuffleOnIcon from "@material-ui/icons/Shuffle"
import TrendingUpIcon from "@material-ui/icons/TrendingUp"
import React from "react"
import { useTranslation } from "react-i18next"
import { Link, useHistory, useLocation } from "react-router-dom"
import CustomMenuHelpLink from "../common/shared/CustomMenuHelpLink"

type MobileMenuProps = {
  mobileMoreAnchorEl:
    | Element
    | ((element: Element) => Element)
    | null
    | undefined
  isMobileMenuOpen: boolean
  handleMobileMenuClose: () => void
}

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(4)
  }
}))

export default function MobileMenu(props: MobileMenuProps) {
  const { mobileMoreAnchorEl, isMobileMenuOpen, handleMobileMenuClose } = props
  const classes = useStyles()
  const { user, logout } = useAuth()
  const { t } = useTranslation()
  const history = useHistory()
  let location = useLocation()
  const module = user?.organisation?.modules?.includes("owner")
    ? "owner"
    : "manager"
  const handleLogout = async () => {
    await logout()
    logoutRedirect({ history: history })
  }
  const orgType = user?.organisation?.type
  const isManager = orgType === "manager"

  return (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {isManager && (
        <MenuItem
          component={Link}
          to="/consultant"
          onClick={handleMobileMenuClose}
        >
          <ListItemIcon>
            <AccountBalanceIcon />
          </ListItemIcon>
          {t("Clients")}
        </MenuItem>
      )}
      <Divider />

      <ModuleSection module="manager">
        <MenuItem
          component={Link}
          to="/manager/clients"
          onClick={handleMobileMenuClose}
        >
          <ListItemIcon>
            <AccountBalanceIcon />
          </ListItemIcon>
          {t("Clients")}
        </MenuItem>
        <ModuleSection module={"cashManagement"}>
          <MenuItem
            component={Link}
            to="/manager/instructions"
            onClick={handleMobileMenuClose}
          >
            <ListItemIcon>
              <ShuffleOnIcon />
            </ListItemIcon>
            {t("Instructions")}
          </MenuItem>
        </ModuleSection>
        <ModuleSection module={"cashManagement"}>
          <MenuItem
            component={Link}
            to="/manager/funds_deprecated"
            onClick={handleMobileMenuClose}
          >
            <ListItemIcon>
              <CardTravelIcon />
            </ListItemIcon>
            {t("Funds")}
          </MenuItem>
        </ModuleSection>
        <ModuleSection module={"corporationList"}>
          <MenuItem
            component={Link}
            to={`/${module}/corporations`}
            onClick={handleMobileMenuClose}
          >
            <ListItemIcon>
              <BusinessIcon />
            </ListItemIcon>
            {t("Funds")}
          </MenuItem>
        </ModuleSection>
        <Divider />
      </ModuleSection>

      <ModuleSection module="owner">
        <MenuItem
          component={Link}
          to="/owner/overview"
          onClick={handleMobileMenuClose}
        >
          <ListItemIcon>
            <PieChartIcon />
          </ListItemIcon>
          {t("Asset Overview")}
        </MenuItem>
        <MenuItem
          component={Link}
          to="/owner/accounts"
          onClick={handleMobileMenuClose}
        >
          <ListItemIcon>
            <AccountTreeIcon />
          </ListItemIcon>
          {t("Accounts")}
        </MenuItem>
        <MenuItem
          component={Link}
          to="/owner/mandates"
          onClick={handleMobileMenuClose}
        >
          <ListItemIcon>
            <AccountBalanceWalletIcon />
          </ListItemIcon>
          {t("Mandates")}
        </MenuItem>
        <MenuItem color="inherit" component={Link} to="/owner/addons">
          <ListItemIcon>
            <ExtensionIcon />
          </ListItemIcon>
          {t("Add-ons")}
        </MenuItem>
        <Divider />
      </ModuleSection>
      <ModuleSection
        module={["cashManagement", "contentAutomation"]}
        organisationPackage={["fxrates", "securities"]}
      >
        <MenuItem disabled>
          <ListItemIcon>
            <EqualizerIcon />
          </ListItemIcon>
          {t("Markets")}
        </MenuItem>
        <List component="div">
          <ModuleSection
            module={["owner", "manager"]}
            organisationPackage={["securities"]}
          >
            <MenuItem
              component={Link}
              to={`/${module}/securities`}
              onClick={handleMobileMenuClose}
              className={classes.nested}
            >
              <ListItemIcon>
                <BusinessIcon />
              </ListItemIcon>
              {t("Securities")}
            </MenuItem>
          </ModuleSection>
          <ModuleSection
            module={["owner", "manager"]}
            organisationPackage={"fxrates"}
          >
            <MenuItem
              component={Link}
              to={`/${module}/fx`}
              onClick={handleMobileMenuClose}
              className={classes.nested}
            >
              <ListItemIcon>
                <LocalATMIcon />
              </ListItemIcon>
              {t("Foreign Exchange")}
            </MenuItem>
          </ModuleSection>
          <ModuleSection module="cashManagement">
            <MenuItem
              component={Link}
              to="/manager/counterparties"
              onClick={handleMobileMenuClose}
              className={classes.nested}
            >
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              {t("Counterparties")}
            </MenuItem>
            <MenuItem
              component={Link}
              to="/manager/products"
              onClick={handleMobileMenuClose}
              className={classes.nested}
            >
              <ListItemIcon>
                <BusinessCenterIcon />
              </ListItemIcon>
              {t("Products")}
            </MenuItem>
            {user?.superuser && (
              <MenuItem
                component={Link}
                to={`/${module}/funds_deprecated`}
                onClick={handleMobileMenuClose}
                className={classes.nested}
              >
                <ListItemIcon>
                  <TrendingUpIcon />
                </ListItemIcon>
                {t("Funds")}
              </MenuItem>
            )}
          </ModuleSection>
        </List>
        <Divider />
      </ModuleSection>
      {user?.superuser && (
        <MenuItem
          component={Link}
          to="/admin/assets"
          onClick={handleMobileMenuClose}
        >
          <ListItemIcon>
            <BookIcon />
          </ListItemIcon>
          <ListItemText primary="Assets" />
        </MenuItem>
      )}

      {user?.superuser && (
        <MenuItem
          key="Entities"
          component={Link}
          to="/admin/entities"
          onClick={handleMobileMenuClose}
        >
          <ListItemIcon>
            <BookIcon />
          </ListItemIcon>
          <ListItemText primary="Entities" />
        </MenuItem>
      )}
      {user?.superuser && (
        <MenuItem
          component={Link}
          to="/admin/entityPackages"
          onClick={handleMobileMenuClose}
        >
          <ListItemIcon>
            <BookIcon />
          </ListItemIcon>
          <ListItemText primary="Entity Packages" />
        </MenuItem>
      )}
      {user?.superuser && (
        <MenuItem
          component={Link}
          to="/admin/organisations"
          onClick={handleMobileMenuClose}
        >
          <ListItemIcon>
            <BookmarksIcon />
          </ListItemIcon>
          <ListItemText primary="Organisations" />
        </MenuItem>
      )}
      {user?.superuser && (
        <MenuItem
          component={Link}
          to="/admin/users"
          onClick={handleMobileMenuClose}
        >
          <ListItemIcon>
            <BookmarksIcon />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </MenuItem>
      )}
      {user?.superuser && (
        <MenuItem
          component={Link}
          to="/admin/uploads"
          onClick={handleMobileMenuClose}
        >
          <ListItemIcon>
            <BookmarksIcon />
          </ListItemIcon>
          <ListItemText primary="Uploads" />
        </MenuItem>
      )}
      {user?.superuser && <Divider />}
      {user && (
        <MenuItem
          component={Link}
          to="/notifications"
          onClick={handleMobileMenuClose}
        >
          <ListItemIcon>
            <NotificationLink noLink />
          </ListItemIcon>
          {t("Notifications")}
        </MenuItem>
      )}
      <MenuItem>
        <ListItemIcon>
          <LanguageIcon />
        </ListItemIcon>
        <LanguageChoice />
      </MenuItem>
      <Divider />
      {user && (
        <MenuItem
          component={Link}
          to={{ pathname: "/settings", state: { from: location } }}
          onClick={handleMobileMenuClose}
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary={t("Settings")} />
        </MenuItem>
      )}
      <CustomMenuHelpLink />
      {user && <Divider />}
      {user && (
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary={t("Logout")} />
        </MenuItem>
      )}
    </Menu>
  )
}
